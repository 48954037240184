import Carousel from 'react-bootstrap/Carousel';

function MissionCarousel() {
  return (
    <Carousel  indicators={false} className='gallery-slides'>
    
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/87.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/97.jpg"
          alt="First slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/107.jpg"
          alt="First slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/117.jpg"
          alt="First slide"
        />
      </Carousel.Item>
         <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/127.jpg"
          alt="First slide"
        />
      </Carousel.Item>
         <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/130.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/136.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/146.jpg"
          alt="First slide"
        />
      </Carousel.Item>
          <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/151.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/78.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/79.jpeg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/80.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/82.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/86.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

    </Carousel>
  );
}

export default MissionCarousel;