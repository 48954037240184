import Carousel from 'react-bootstrap/Carousel';

function VisionCarousel() {
  return (
    <Carousel indicators={false} className='gallery-slides'>
     
      
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/15.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/16.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/17.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/18.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/19.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/20.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/21.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/22.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/23.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/24.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/25.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
        <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/26.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
        <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/27.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
        <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/28.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/29.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/30.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/31.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/32.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/33.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/34.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/35.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/36.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/37.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/38.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/39.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
      
    </Carousel>
  );
}

export default VisionCarousel;