import React,{useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaQuoteLeft, FaArrowAltCircleRight,FaCheckCircle,FaHandshake,FaPlay} from 'react-icons/fa';
import {BsBarChartFill} from 'react-icons/bs';
import { IoIosMusicalNote } from "react-icons/io";
import { GoMegaphone } from "react-icons/go";
import { GiDolphin } from "react-icons/gi";
import VisionCarousel from '../components/Vision Carousel';
import CoreValues from '../components/CoreValues';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

useEffect(()=>{
document.title = 'Dolphine Entertainment Uganda Limited';

},[]);


  return (
    <>
    <section>
      <CarouselFade></CarouselFade>
    </section>
     {/* <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
          <div className='content-container vm-container'>
                 <div className='vision-mission-content vision-mission-content-a'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info vision-mission-info-a'>
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>
                            Our business brings advanced telemetric (GPS tracking combined with wireless communication) products to the consumer market. Our product allows consumers to track the location and manage their vehicle with their mobile phone/computers and be notified when unexpected events such as a hijacking, accident or break-in occur.</p>
                            <p>  Our product is positioned as the next generation car alarm - silent with features that are useful every day, not just when a theft is attempted. We also support personal tracking devices such as GPS enabled phones to further improve the "peace of mind" of our customers. We will also be adding support for other wireless networks to improve the coverage of our products.
</p>
                          
                          <h3 className='title-h3'>Advantages of our solutions</h3>
                          <div className='vm-line'></div>
                          <p>
                          Our system has been proven to be efficient and highly competitive. It assists our clients achieve the following goals:
</p>
<ul>
<li>Cut operational costs.</li>
<li>Increase revenue.</li>
<li>Monitor and control the movement of the vehicle.</li>
<li>Save lives.</li>
<li>Fuel control.</li>

</ul>


                    </article>

                    


                 </div>

          </div>
    </section> */}
     {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/9.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'4rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Why Choose Us?</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span><strong>Wanway Solutions Ltd</strong> is one of the leaders in tracking services and is committed to ensuring that you are always aware of the location of your asset. You monitor it yourself and therefore you can’t lose it!

</p>
<p>We provide excellent customer care services and follow-up on our customers through maintenance and regular upgrades on our systems.

</p>
        </div>
    </section> */}
    <section className='section-cover bg-cover bg-cover-a'>
     <GiDolphin className='icon-a' /> <GiDolphin  className='icon-b'/>
        <div className='content-container content-container-a content-container-b'>
          <h2 className="home-h2 home-h2-a">Get to know us</h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>
          <p className='home-p'>We're an advertising and media
agency with vast experience in
digital and strategic marketing
promotions. Our wide range of
services include digital
marketing, media booking,
promotion strategy and several
other advertising and mediarelated support services.
</p>

                 <p className='home-p'><strong>Dolphine Entertainment Uganda Limited</strong> has made it a mission to add value
to brands by creating experiences that are innovative,
engaging and tailored to the needs of clients.

</p>


  <Link to='events' className='events-link' onClick={scrollFunc}>Our Events</Link>
 
 </div>
              
    </section>
    <section className='section-cover who-we-are-cover who-we-are-cover-a' >
      {/* <h2 className="home-h2 wwa-h2">Our Works</h2> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                         
                          <img src='./images/77.jpg' className='vision-image vision-image-a'></img>
                             <h4 className='home-h4'>Robert Sekidde (a.k.a Tuff B)<br></br><em><span className='home-span'>CEO at Dolphine Entertainment</span></em> </h4>
                    </article>

                    <article className='vision-mission-info vision-mission-info-b vision-mission-info-c'>
                          {/* <h3 className='title-h3 title-h3-a'>Our Vision</h3>
                          
                           <div className='vm-line'></div> */}
                          
<p>"An ideation ain't
work without
execution."</p>
                          
                         

                    </article>

                    


                 </div>

          </div>
    </section>
       
<section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("./images/5.jpeg")`,
  
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'5rem 0'

    }}>
          <div className='quote-content'>
             <h2 className='quote-h3'>Why Choose Us?</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span> On top of landing you the best media
deals, our team is made up of experts
from different backgrounds who have
years of experience in the media industry. We know what works and what doesn't, so
we can help you craft the perfect
campaign that will work for your brand
and bring the media along the trail.

</p>
            
        </div>
    </section> 

    <section className='section-cover who-we-are-cover who-we-are-cover-a' >
      <h2 className="home-h2 wwa-h2">Our Works</h2>
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                         
                          <img src='./images/1.jpeg' className='vision-image'></img>

                    </article>

                    <article className='vision-mission-info vision-mission-info-b'>
                          {/* <h3 className='title-h3 title-h3-a'>Our Vision</h3>
                          
                           <div className='vm-line'></div> */}
                          
<p>Dolphine Entertainment has made it a
mission to add value to brands by
creating experiences that are innovative,
engaging, and tailored to the needs of
clients. We understand the right
solutions for our clients from digital
marketing, and media strategy all the
way to promotions and advertising
strategy. We are leaders in this business
and here are a few of the amazing clients
and partners that we have worked with.</p>
                          
                         

                    </article>

                    


                 </div>

          </div>
    </section>
  
   
     
    {/* <section className='section-cover section-cover-core-values' >
      <h2 className="home-h2 home-h2-a home-h2-b">Our Core Values</h2>
      <div className='container'>
              <CoreValues/>
      </div>

    </section> */}
  
    
 {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'><strong> REJO ENGINEERING LIMITED</strong> began as a general works contractor in 2014. Over the
years, the company has undertaken many challenging projects and accumulated skills, know-how and
experiences in delivering building projects, water projects, road and bridge construction
projects and build solutions, project management services, building trades and related
engineering works.
<br></br> <br></br>
Today, <strong>REJO ENGINEERING LIMITED</strong> takes on the role of main contractor for small,
medium and large size construction projects and performs project management services to
coordinate specialist trades for industrial/commercial projects. We also provide design inputs
and engineering solutions as value-add services to our clients.
<br></br> <br></br>
We take pride in our delivery, thus our clients can always be assured that only the most experienced and qualified people are serving them, all the time.</p>
                     </div>


                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>

       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2>
     <h2 className='home-h2 home-service-h2'>Our Services</h2>
    <div className='line1'> 
    <div className='line2'></div>
    </div>
    
       <div className='home-services-data'>
       <div className='home-services-info '>


       
       
          <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>CCTV Installation</h3>
          <img src='./images/16.jpg' className='home-service-image'></img>
          <Link to='#' className='home-link'>See Details</Link>
          <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p>

        </div>
   


    </div>
        
<Link to='features' className='home-btn' onClick={scrollFunc}>Key Features</Link>

        </div>

  </section> */}
 {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.863)), url("./images/4.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Objectives</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'> To provide the most efficient installation, repair
and maintenance solutions for clients’
dependable work continuity.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To ensure timely, correct and best quality
delivery of services and supplies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To grow and maintain trusted relationships
with our clients, partners and government.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To foster client up-to-date market awareness.

</p>
                     </div>
                    
                </div>
                   

           </article>

           




       </div>

  </section> */}

  


  {/* <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
           <div className='line1'> 
           <div className='line2'></div>
           </div>
          <p className='clients-para'></p>
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/1.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/2.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/3.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/4.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/5.png' alt='client' className='client-image'></img>
           </article>

          </div> 
    </section> */}
   
     {/* <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h3 className='title-h3'>How Our System Works?</h3>
              <div className='vm-line'></div>
                 <p className='home-p home-p-a'>
      <strong>1. The Tracking Unit:</strong> A tracking unit is covertly installed in the vehicle. This is done to make sure that the unit is not sabotaged or damaged in any case. The tracking unit receives GPS signal and the on-board processor translates and encodes these signals. This information together with other vehicle status such as Current speed, ignition, battery voltage, mileage etc. are all packed together into a single transmission data, encoded, encrypted and transmitted directly to our Control Center using the widely available GSM Network. 

</p>
<p className='home-p home-p-a'>
     <strong>2. The Control Center:</strong> Our Control Center receives the data sent from the tracking unit, decodes and decrypts the message with all the information about the vehicle and feeds it into the Control Center Application (CCA). The Control Center software uses this data to identify the transmitting unit(s) details, locations and maps. 
</p>

<p className='home-p home-p-a'>
   <strong>3. Tracking Website:</strong>  The web server receives information from the Central Control Application which is equipped with powerful map engine and a map server. It translates the data and displays on the map the different status of the vehicle. The site is fully secured to avoid compromising your vehicle security. 

</p>





 
        </div>

    </section> */}
       {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/11.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Clientele</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list-a'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Individuals. 
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Tour & Travel Operators.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Transportation & Haulage Companies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'> Taxi Operators. </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Delivery & Distribution Companies. </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Leasing & Car Rental Companies.</p>
                     </div>

                </div>
                   

           </article>

       </div>
                    
  </section> */}
  
    </>
  )
}

export default Home